<template>
  <div>
    <v-dialog
      v-model="dialog"
      v-if="selectedRow"
      max-width="80%"
      @click:outside="$emit('update:dialog', false)"
    >
      <v-card>
        <v-card-title class="primary--text px-4 py-1 text-body-1">
          {{
            selectedRow.description !== 'login' ?
              title(selectedRow)
            : selectedRow.subject_type === 'Employee' ? selectedRow.subject_id + '. ' + $t("employee.employee") + ' ' + $t("activityLog.loginLog") : selectedRow.subject_type === 'Company' ? selectedRow.subject_id + '. ' + $t("company.company") + ' ' + $t("activityLog.loginLog") : $t("activityLog.activityLogDetailsModal.header")
          }}
          <v-spacer />
          <v-btn icon @click="$emit('update:dialog', false)">
            <v-icon color="primary darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <template>
          <v-card-text v-if="selectedRow.properties_new || selectedRow.description">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="width: 250px;">
                      {{ $t("activityLog.activityLogDetailsModal.date") }}
                    </th>
                    <th>
                      {{ $t("activityLog.activityLogDetailsModal.user") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'QuotationItem'">
                      {{ $t("quotation.quotation") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'OrderItem'">
                      {{ $t("order.order") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'DeliveryNoteItem'">
                      {{ $t("delivery.delivery") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'InvoiceItem'">
                      {{ $t("invoice.invoice") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'ReturnCertificateItems'">
                      {{ $t("returnCertificate.returnCertificate") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'ReceiptTaxInvoiceItems'">
                      {{ $t("receiptTax.receiptTaxInvoice") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'CreditInvoiceItem'">
                      {{ $t("creditInvoice.creditInvoice") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'DepositSub'">
                      {{ $t("deposit.deposit") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'ReceiptPayment'">
                      {{ $t("receipt.receipt") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'BookkeepingTwo'">
                      {{ $t("bookkeeping.income") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'PurchaseOrderItem'">
                      {{ $t("route.purchaseOrder") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'SupplierDeliveryNoteItems'">
                      {{ $t("route.supplierDeliveryNote") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'SupplierInvoiceItems'">
                      {{ $t("route.supplierInvoice") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'SupplierCreditInvoiceItems'">
                      {{ $t("route.supplierCreditInvoice") }}
                    </th>
                    <th style="width: 150px;" v-if="selectedRow.subject_type === 'SupplierReceiptPayment'">
                      {{ $t("route.supplierPayment") }}
                    </th>
                    <th style="width: 250px;">
                      {{ $t("activityLog.activityLogDetailsModal.property") }}
                    </th>
                    <th style="width: 250px;">
                      {{ $t("activityLog.activityLogDetailsModal.previous") }}
                    </th>
                    <th style="width: 250px;">
                      {{ $t("activityLog.activityLogDetailsModal.new") }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="selectedRow.description === 'updated'">
                  <tr
                    v-for="(value, propertyName, index) in difference(
                      JSON.parse(selectedRow.properties_new),
                      JSON.parse(selectedRow.properties_old)
                    )"
                    :key="index"
                  >
                    <td>{{ profileById.date_format ? formatDateDDMMYYYY(new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(11,19) : parseDateYYYYMMDD(new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(11,19) }}</td>
                    <td>{{ String(selectedRow.user.email) }}</td>
                    <td v-if="selectedRow.subject_type === 'QuotationItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'OrderItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'DeliveryNoteItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'InvoiceItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'ReceiptTaxInvoiceItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'CreditInvoiceItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'DepositSub'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'ReturnCertificateItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'ReceiptPayment'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'BookkeepingTwo'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'PurchaseOrderItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'SupplierDeliveryNoteItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'SupplierInvoiceItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'SupplierCreditInvoiceItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'SupplierReceiptPayment'">{{ documentId }}</td>
                    <td style="fontweight: 500">{{ propertyName }}</td>
                    <td>{{ `${value.old}` }}</td>
                    <td>{{ `${value.new}` }}</td>
                  </tr>
                </tbody>
                <tbody v-else-if="selectedRow.description === 'inserted'">
                  <tr
                    v-for="(value, propertyName, index) in difference(
                      JSON.parse(selectedRow.properties_new),
                      JSON.parse('{}')
                    )"
                    :key="index"
                  >
                    <td>{{ profileById.date_format ? formatDateDDMMYYYY(new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(11,19) : parseDateYYYYMMDD(new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(11,19) }}</td>
                    <td>{{ String(selectedRow.user.email) }}</td>
                    <td v-if="selectedRow.subject_type === 'QuotationItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'OrderItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'DeliveryNoteItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'InvoiceItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'ReceiptTaxInvoiceItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'CreditInvoiceItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'ReturnCertificateItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'DepositSub'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'ReceiptPayment'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'BookkeepingTwo'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'PurchaseOrderItem'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'SupplierDeliveryNoteItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'SupplierInvoiceItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'SupplierCreditInvoiceItems'">{{ documentId }}</td>
                    <td v-else-if="selectedRow.subject_type === 'SupplierReceiptPayment'">{{ documentId }}</td>
                    <td style="fontweight: 500">{{ propertyName }}</td>
                    <td>{{ "-" }}</td>
                    <td>{{ `${value.new}` }}</td>
                  </tr>
                </tbody>
                <tbody v-else-if="selectedRow.description === 'login'">
                  <tr>
                    <td>{{ profileById.date_format ? formatDateDDMMYYYY(new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(11,19) : parseDateYYYYMMDD(new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(selectedRow.updated_at).toLocaleString('sv-SE').slice(11,19) }}</td>
                    <td>{{ String(selectedRow.user.email) }}</td>
                    <td style="fontweight: 500">last_logged_in</td>
                    <td>{{ profileById.date_format ? formatDateDDMMYYYY(new Date(selectedRow.user.last_logged_in).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(selectedRow.user.last_logged_in).toLocaleString('sv-SE').slice(11,19) : parseDateYYYYMMDD(new Date(selectedRow.user.last_logged_in).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(selectedRow.user.last_logged_in).toLocaleString('sv-SE').slice(11,19) }}</td>
                    <td>{{ "-" }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else>
            {{ $t("common.noDataFound") }}</v-card-text
          >
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';
export default {
  name: "ActivityLogDialog",
  props: ["dialog", "selectedRow", "resetRow"],
  components: {},
  data() {
    return {
      skeleton: false,
      documentId: '',
      zTableValue: null
    };
  },
  computed: {
    ...mapGetters({
      profileById: 'profile/profileById',
    })
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.resetRow();
      }
    },
  },
  methods: {
    parseDateYYYYMMDD:parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    title(selectedRow) {
      if (selectedRow.subject_type === 'Employee') {
        return selectedRow.subject_id + '. ' + this.$t("employee.employee") + ' ' + this.$t("activityLog.log")
      } if (selectedRow.subject_type === 'Role') {
        return selectedRow.subject_id + '. ' + this.$t("roles.role") + ' ' + this.$t("activityLog.log")
      } if (selectedRow.subject_type === 'Company') {
        return selectedRow.subject_id + '. ' + this.$t("company.company") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Invoice') {
        return selectedRow.subject_id + '. ' + this.$t("invoice.invoice") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'InvoiceItem') {
        return selectedRow.subject_id + '. ' + this.$t("invoice.invoiceItem") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'ReceiptTaxInvoiceItems') {
        return selectedRow.subject_id + '. ' + this.$t("receiptTax.receiptTaxItem") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'DeliveryNote') {
        return selectedRow.subject_id + '. ' + this.$t("delivery.delivery") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'DeliveryNoteItem') {
        return selectedRow.subject_id + '. ' + this.$t("delivery.deliveryItem") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Quotation') {
        return selectedRow.subject_id + '. ' + this.$t("quotation.quotation") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'QuotationItem') {
        return selectedRow.subject_id + '. ' + this.$t("quotation.quotationItem") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Ztable') {
        return selectedRow.subject_id + '. ' + this.$t("auxiliaryTable.auxiliaryTable") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Order') {
        return selectedRow.subject_id + '. ' + this.$t("order.order") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'OrderItem') {
        return selectedRow.subject_id + '. ' + this.$t("order.orderItem") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Deposit') {
        return selectedRow.subject_id + '. ' + this.$t("deposit.deposit") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'DepositSub') {
        return selectedRow.subject_id + '. ' + this.$t("deposit.depositItem") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Receipt') {
        return selectedRow.subject_id + '. ' + this.$t("receipt.receipt") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'ReceiptPayment') {
        return selectedRow.subject_id + '. ' + this.$t("receipt.receiptItem") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'CreditInvoice') {
        return selectedRow.subject_id + '. ' + this.$t("creditInvoice.creditInvoice") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'CreditInvoiceItem') {
        return selectedRow.subject_id + '. ' + this.$t("creditInvoice.creditInvoiceItem") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Bank') {
        return selectedRow.subject_id + '. ' + this.$t("bank.bank") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Item') {
        return selectedRow.subject_id + '. ' + this.$t("product.product") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Supplier') {
        return selectedRow.subject_id + '. ' + this.$t("supplier.supplier") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Customer') {
        return selectedRow.subject_id + '. ' + this.$t("customer.customer") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'ReturnCertificate') {
        return selectedRow.subject_id + '. ' + this.$t("returnCertificate.returnCertificate") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'ReturnCertificateItems') {
        return selectedRow.subject_id + '. ' + this.$t("returnCertificate.returnCertificateItems") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'BookkeepingTwo') {
        return selectedRow.subject_id + '. ' + this.$t("bookkeeping.income") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'Purchase') {
        return selectedRow.subject_id + '. ' + this.$t("route.purchaseOrder") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'PurchaseOrderItem') {
        return selectedRow.subject_id + '. ' + this.$t("purchaseOrder.purchaseOrderItems") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'SupplierDeliveryNote') {
        return selectedRow.subject_id + '. ' + this.$t("route.supplierDeliveryNote") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'SupplierDeliveryNoteItems') {
        return selectedRow.subject_id + '. ' + this.$t("supplierDeliveryNote.supplierDeliveryNoteItems") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'SupplierInvoice') {
        return selectedRow.subject_id + '. ' + this.$t("route.supplierInvoice") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'SupplierInvoiceItems') {
        return selectedRow.subject_id + '. ' + this.$t("supplierInvoice.supplierInvoiceItems") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'SupplierCreditInvoice') {
        return selectedRow.subject_id + '. ' + this.$t("route.supplierCreditInvoice") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'SupplierCreditInvoiceItems') {
        return selectedRow.subject_id + '. ' + this.$t("supplierCreditInvoice.supplierCreditInvoiceItems") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'SupplierPayment') {
        return selectedRow.subject_id + '. ' + this.$t("route.supplierPayment") + ' ' + this.$t("activityLog.log")
      } else if (selectedRow.subject_type === 'SupplierReceiptPayment') {
        return selectedRow.subject_id + '. ' + this.$t("supplierPayment.supplierPaymentItem") + ' ' + this.$t("activityLog.log")
      } else {
        return this.$t("activityLog.activityLogDetailsModal.header")
      }
    },
    getZtableValue(id) {
      let self = this;
      this.$store.dispatch('auxiliarytable/GetAuxiliarytableById', id).then(data => {
        self.zTableValue = data.table_name;
      });
      return this.zTableValue
    },
    getDate(date2) {
      return `${date2.getFullYear()}-${Number(date2.getMonth()) + 1 < 10 ? "0" + String(Number(date2.getMonth()) + 1) : String(Number(date2.getMonth()) + 1)}-${Number(date2.getDate()) < 10 ? "0" + date2.getDate() : date2.getDate()}`;
    },
    difference(obj1, obj2) {
      if(this.selectedRow.subject_type === 'QuotationItem') {
        this.documentId = obj1.quotationId;
      } else if (this.selectedRow.subject_type === 'OrderItem') {
        this.documentId = obj1.orderId;
      } else if (this.selectedRow.subject_type === 'DeliveryNoteItem') {
        this.documentId = obj1.deliveryNoteId;
      } else if (this.selectedRow.subject_type === 'InvoiceItem') {
        this.documentId = obj1.invoiceId;
      } else if (this.selectedRow.subject_type === 'ReceiptTaxInvoiceItems') {
        this.documentId = obj1.receiptTaxInvoiceId;
      } else if (this.selectedRow.subject_type === 'ReturnCertificateItems') {
        this.documentId = obj1.returnCertificateId;
      } else if (this.selectedRow.subject_type === 'DepositSub') {
        this.documentId = obj1.depositId;
      } else if (this.selectedRow.subject_type === 'CreditInvoiceItem') {
        this.documentId = obj1.creditInvoiceId;
      } else if (this.selectedRow.subject_type === 'ReceiptPayment') {
        this.documentId = obj1.receiptId;
      } else if (this.selectedRow.subject_type === 'BookkeepingTwo') {
        this.documentId = obj1.incomeId;
      } else if (this.selectedRow.subject_type === 'PurchaseOrderItem') {
        this.documentId = obj1.purchaseOrderId;
      } else if (this.selectedRow.subject_type === 'SupplierDeliveryNoteItems') {
        this.documentId = obj1.supplierDeliveryNoteId;
      } else if (this.selectedRow.subject_type === 'SupplierInvoiceItems') {
        this.documentId = obj1.supplierInvoiceId;
      } else if (this.selectedRow.subject_type === 'SupplierCreditInvoiceItems') {
        this.documentId = obj1.supplierCreditInvoiceId;
      } else if (this.selectedRow.subject_type === 'SupplierReceiptPayment') {
        this.documentId = obj1.supplierReceiptId;
      } 
      let result = {};
      if (Object.keys(obj2).length > 0) {
        Object.entries(obj1).forEach(([key, value]) => {
          if (value !== obj2[key]) {
            let newVal = Number(value);
            let oldVAl = Number(obj2[key]);
            if (newVal === oldVAl) return;
            if (key === "startingDate" || key === "endDate" || key === "customerOpeningDate" || key === "customerEndDate" || key === "supplierOpeningDate" || key === "supplierEndDate" || key === "deliveryDate" || key === "quotationDate" || key === "quotationValidity" || key === "orderDate" || key === "deliveryDate" || key === "invoiceYear" || key === "invoiceDate" || key === "paymentDate" || key === "creditInvoiceDate" || key === "lastPurchaseDate" || key === "lastSaleDate") {
              result[key] = {
                new: this.profileById.date_format ? formatDateDDMMYYYY(value) : parseDateYYYYMMDD(value) ? this.profileById.date_format ? formatDateDDMMYYYY(new Date(value).toISOString().substring(0,10)) : parseDateYYYYMMDD(new Date(value).toISOString().substring(0,10)) : '-',
                old: this.profileById.date_format ? formatDateDDMMYYYY(obj2[key].slice(0,10)) : parseDateYYYYMMDD(obj2[key].slice(0,10)) ? this.getDate(new Date(obj2[key])) : '-',
              };
              return;
            }
            if (key === "dateTimePrinted" || key === "timePrinted" || key === "created_at" || key === "updated_at" || key === "createdAt" || key === "updatedAt") {
              result[key] = {
                new: this.profileById.date_format ? formatDateDDMMYYYY(new Date(value).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(value).toLocaleString('sv-SE').slice(11,19) : parseDateYYYYMMDD(new Date(value).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(value).toLocaleString('sv-SE').slice(11,19),
                old: this.profileById.date_format ? formatDateDDMMYYYY(new Date(obj2[key]).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(obj2[key]).toLocaleString('sv-SE').slice(11,19) : parseDateYYYYMMDD(new Date(obj2[key]).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(obj2[key]).toLocaleString('sv-SE').slice(11,19),
              };
              return;
            }
            if (key === 'ztable_name_id') {
              result[key] = {
                new: value ? this.getZtableValue(value) : '-',
                old: obj2[key] ? this.getZtableValue(obj2[key]) : '-',
              };
              return;
            }
            if (key === "password") {
              result[key] = {
                new: '*****',
                old: '*****',
              };
              return;
            }
            result[key] = {
              new: isNaN(newVal) ? value : newVal,
              old: isNaN(oldVAl) ? obj2[key] : oldVAl,
            };
          }
        });
      } else {
        Object.entries(obj1).forEach(([key, value]) => {
          if (key === "startingDate" || key === "endDate" || key === "customerOpeningDate" || key === "customerEndDate" || key === "supplierOpeningDate" || key === "supplierEndDate" || key === "deliveryDate" || key === "quotationDate" || key === "quotationValidity" || key === "orderDate" || key === "deliveryDate" || key === "invoiceYear" || key === "invoiceDate" || key === "paymentDate" || key === "creditInvoiceDate" || key === "lastPurchaseDate" || key === "lastSaleDate") {
            result[key] = {
              new: this.profileById.date_format ? formatDateDDMMYYYY(value) : parseDateYYYYMMDD(value) ? this.profileById.date_format ? formatDateDDMMYYYY(new Date(value).toISOString().substring(0,10)) : parseDateYYYYMMDD(new Date(value).toISOString().substring(0,10)) : '-',
              old: this.profileById.date_format ? formatDateDDMMYYYY(obj2[key].slice(0,10)) : parseDateYYYYMMDD(obj2[key].slice(0,10)) ? this.getDate(new Date(obj2[key])) : '-',
            };
            return;
          }
          if (key === "dateTimePrinted" || key === "timePrinted" || key === "created_at" || key === "updated_at" || key === "createdAt" || key === "updatedAt") {
            result[key] = {
              new: this.profileById.date_format ? formatDateDDMMYYYY(new Date(value).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(value).toLocaleString('sv-SE').slice(11,19) : parseDateYYYYMMDD(new Date(value).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(value).toLocaleString('sv-SE').slice(11,19),
              old: this.profileById.date_format ? formatDateDDMMYYYY(new Date(obj2[key]).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(obj2[key]).toLocaleString('sv-SE').slice(11,19) : parseDateYYYYMMDD(new Date(obj2[key]).toLocaleString('sv-SE').slice(0,10)) + " " + new Date(obj2[key]).toLocaleString('sv-SE').slice(11,19),
            };
            return;
          }
          if (key === 'ztable_name_id') {
            result[key] = {
              new: value ? this.getZtableValue(value) : '-',
              old: obj2[key] ? this.getZtableValue(obj2[key]) : '-',
            };
            return;
          }
          if (key === "password") {
            result[key] = {
              new: '*****',
              old: '*****',
            };
            return;
          }
          result[key] = {
            new: value,
            old: "-",
          };
        });
      }
      return result;
    },
  },
};
</script>